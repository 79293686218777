import { ApiService, ApiError } from './api.service'

const ContactService = {
  postContact: async function (form) {
    const resource = '/wordpress/wp-json/wp/v2/contact/send'
    try {
      const response = await ApiService.post(
        resource,
        {
          name: form.name,
          email: form.email,
          message: form.message,
        },
        {}
      )
      return response.data
    } catch (error) {
      throw new ApiError(error.response.status, error.response.data.detail)
    }
  },
}

export default ContactService

export { ContactService, ApiError }
