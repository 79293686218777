<template>
  <div id="default-template">
    <header-fixed />
    <header-sticky />
    <header-scroll class="mt-5" />
    <slot />
    <footer-fixed />
  </div>
</template>

<script>
import HeaderFixed from '../components/HeaderFixed'
import HeaderSticky from '../components/HeaderSticky'
import HeaderScroll from '../components/HeaderScroll'
import FooterFixed from '../components/FooterFixed'

export default {
  name: 'LayoutDfault',
  components: {
    HeaderFixed,
    HeaderSticky,
    HeaderScroll,
    FooterFixed,
  },
}
</script>

<style lang="scss">
#default-template {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
