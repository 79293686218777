<template>
  <b-container>
    <b-row align-h="center" class="text-center mt-2 mb-5">
      <b-col class="text-center px-sm-0">
        <logo-picture />
        <header-menu />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import HeaderMenu from './HeaderMenu'
import LogoPicture from './LogoPicture'
import { BRow, BCol, BContainer } from 'bootstrap-vue'

export default {
  name: 'HeaderScroll',
  components: {
    HeaderMenu,
    LogoPicture,
    'b-container': BContainer,
    'b-row': BRow,
    'b-col': BCol,
  },
}
</script>
