<template>
  <div>
    <b-form @submit="onSubmitContact" v-if="!submitted && !errored">
      <b-form-input
        id="input-1"
        v-model="form.name"
        required
        v-bind:placeholder="$t('message.contact.name')"
        class="rounded mb-1"
      ></b-form-input>
      <b-form-input
        id="input-2"
        v-model="form.email"
        type="email"
        required
        v-bind:placeholder="$t('message.contact.email')"
        class="rounded mb-1"
      ></b-form-input>
      <div class="mb-3">
        <b-form-textarea
          id="textarea"
          v-model="form.message"
          required
          v-bind:placeholder="$t('message.contact.text')"
          rows="3"
          class="rounded-lg"
        ></b-form-textarea>
      </div>
      <b-button
        type="submit"
        variant="primary"
        class="mb-3 contact-button"
        :disabled="submitting"
      >
        <b-spinner v-if="submitting" small></b-spinner>
        {{ $t('message.contact.submit') }}
      </b-button>
    </b-form>
    <div v-if="submitted" class="text-secondary">
      {{ $t('message.contact.submitted') }}
    </div>
    <div v-if="errored" class="text-danger">
      {{ $t('message.contact.error') }}
    </div>
  </div>
</template>

<script>
import { ContactService } from '../services/contact.service'
import {
  BButton,
  BForm,
  BFormInput,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'ContactForm',
  components: {
    'b-button': BButton,
    'b-spinner': BSpinner,
    'b-form': BForm,
    'b-form-input': BFormInput,
    'b-form-textarea': BFormTextarea,
  },
  data() {
    return {
      errored: false,
      form: {
        name: '',
        email: '',
        message: '',
      },
      submitting: false,
      submitted: false,
    }
  },
  methods: {
    onSubmitContact(evt) {
      evt.preventDefault()
      this.$ga.event('contact', 'submit form', this.form.name)
      this.submitting = true
      ContactService.postContact(this.form)
        .then(() => {
          this.submitted = true
        })
        .catch(() => {
          this.errored = true
        })
        .finally(() => (this.submitting = false))
    },
  },
}
</script>

<style scoped lang="scss">
.contact-button {
  color: $contact-button-color;
  font-weight: 500;
}
</style>
