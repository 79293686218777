var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-default-dynamic',[_c('b-container',[_c('b-row',{staticClass:"mb-5"},[(_vm.showMap)?_c('GmapMap',{staticStyle:{"width":"100%","height":"300px"},attrs:{"center":_vm.googleMap.center,"zoom":_vm.googleMap.zoom,"map-type-id":_vm.googleMap.mapTypeId,"options":{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          disableDefaultUi: false,
          styles: _vm.googleMap.styles,
        }}},[_c('GmapMarker',{attrs:{"position":_vm.googleMap.marker.position,"clickable":false,"draggable":false,"icon":_vm.googleMap.marker.icon}})],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"px-sm-0"},[(_vm.contentErrored)?_c('section',[_c('p',[_vm._v("Sorry er is iets mis gegaan met het laden van deze pagina...")])]):_c('section',[(_vm.contentLoading)?_c('div',{staticClass:"mb-3"},[_c('skeleton-box',{attrs:{"height":"38px","width":"30%"}}),_c('skeleton-box',{attrs:{"height":"20px","width":"80%"}})],1):_c('div',[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.content.title.rendered)}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.content.content.rendered)}})])]),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'whatsapp'],"size":"lg"}})],1),_c('b-col',{attrs:{"cols":"8"}},[_c('whatsapp-link')],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'envelope'],"size":"lg"}})],1),_c('b-col',{attrs:{"cols":"8"}},[_c('a',{attrs:{"href":"mailto:info@zijschittert.nl"}},[_vm._v("info@zijschittert.nl")])])],1)],1),_c('b-col',{staticClass:"pr-sm-0",attrs:{"md":"5"}},[_c('contact-form')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }