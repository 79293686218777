<template>
  <layout-default-dynamic>
    <b-container>
      <b-row class="mb-5">
        <GmapMap
          v-if="showMap"
          style="width: 100%; height: 300px"
          :center="googleMap.center"
          :zoom="googleMap.zoom"
          :map-type-id="googleMap.mapTypeId"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            disableDefaultUi: false,
            styles: googleMap.styles,
          }"
        >
          <GmapMarker
            :position="googleMap.marker.position"
            :clickable="false"
            :draggable="false"
            :icon="googleMap.marker.icon"
          />
        </GmapMap>
      </b-row>
      <b-row>
        <b-col class="px-sm-0">
          <section v-if="contentErrored">
            <p>Sorry er is iets mis gegaan met het laden van deze pagina...</p>
          </section>
          <section v-else>
            <div v-if="contentLoading" class="mb-3">
              <!-- <div> -->
              <skeleton-box height="38px" width="30%" />
              <skeleton-box height="20px" width="80%" />
            </div>
            <div v-else>
              <!-- <div> -->
              <h1 v-html="content.title.rendered"></h1>
              <span v-html="content.content.rendered"></span>
            </div>
          </section>
          <b-row class="mb-2">
            <b-col cols="1" class="text-center">
              <font-awesome-icon :icon="['fab', 'whatsapp']" size="lg" />
            </b-col>
            <b-col cols="8">
              <whatsapp-link />
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="1" class="text-center">
              <font-awesome-icon :icon="['fas', 'envelope']" size="lg" />
            </b-col>
            <b-col cols="8">
              <a href="mailto:info@zijschittert.nl">info@zijschittert.nl</a>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="5" class="pr-sm-0">
          <contact-form />
        </b-col>
      </b-row>
    </b-container>
  </layout-default-dynamic>
</template>

<script>
import '../plugins/googlemaps'
import LayoutDefaultDynamic from '../layouts/LayoutDefaultDynamic'
import { WpService } from '../services/wp.service'
import { mapState } from 'vuex'
import { Styles } from '../plugins/googlemaps'
import WhatsappLink from '../components/WhatsappLink'
import ContactForm from '../components/ContactForm'
import SkeletonBox from '../components/SkeletonBox'
import { BRow, BCol, BContainer } from 'bootstrap-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
library.add(faWhatsapp, faEnvelope)

export default {
  name: 'Contact',
  components: {
    LayoutDefaultDynamic,
    WhatsappLink,
    ContactForm,
    SkeletonBox,
    'b-container': BContainer,
    'b-row': BRow,
    'b-col': BCol,
    'font-awesome-icon': FontAwesomeIcon,
  },

  props: {
    id: {
      type: [Number, String],
    },
  },
  data() {
    return {
      showMap: false,
      googleMap: {
        center: { lat: 52.3909, lng: 6.0496 },
        zoom: 9,
        mapTypeId: 'roadmap',
        styles: Styles,
        marker: {
          position: { lat: 52.3909, lng: 6.0496 },
          icon: {
            url: require('../assets/conceptLogo.png'),
            scaledSize: { width: 50, height: 50, f: 'px', b: 'px' },
          },
        },
      },
      contentErrored: false,
      contentLoading: true,
      content: undefined,
    }
  },
  computed: {
    ...mapState(['lang']),
  },
  watch: {
    lang(newValue) {
      this.getContent(newValue, this.id)
    },
  },
  methods: {
    getContent(lang, id) {
      WpService.page(id, lang)
        .then((response) => (this.content = response))
        .catch(() => {
          this.contentErrored = true
        })
        .finally(() => (this.contentLoading = false))
    },
  },
  mounted() {
    this.getContent(this.lang, this.id)
    this.$nextTick(() => {
      // voorkom te vroeg laden van maps en javascript fout
      this.showMap = true
    })
  },
  metaInfo() {
    return {
      title: `zijschittert - contact`,
      meta: [
        {
          name: 'description',
          content: 'Hoi! Ik hoor graag van je. - Hi! I like to hear from you.',
        },
      ],
    }
  },
}
</script>
